import React from 'react'

import Layout from '../components/layout'
import {Grid, Typography, Container, Box, makeStyles} from '@material-ui/core';

import MidSiteLogo from '../../assets/logo_midsite.svg';

import BinaryBackground from '../../assets/expertise/back_binary_wide_201117.svg';

import ExpertiseIconBrain from '../../assets/expertise/expertise_icon_brain_210323.svg';
import ExpertiseIconRocket from '../../assets/expertise/expertise_icon_rocket_210323.svg';
import ExpertiseIconTool from '../../assets/expertise/expertise_icon_tool_210323.svg';

import Call2AIconPhone from '../../assets/contact/call2a_phone_210320.svg';
import Call2AIconEmail from '../../assets/contact/call2a_email_210320.svg';
import Call2AIconChat from '../../assets/contact/call2a_chat_210320.svg';

import SolutionsBackground from '../../assets/solutions/back_solutions_pcb_201210.svg'
import SolutionsIconRegex from '../../assets/solutions/solution_icons_regex_TRIM_210319.svg';
import SolutionsIconAI from '../../assets/solutions/solution_icons_ai_TRIM_210319.svg';
import SolutionsIconBespoke from '../../assets/solutions/solution_icons_bespoke_TRIM_210319.svg';

import Call2AIconMail from '../../assets/contact/call2a_mail_210320.svg';
import Call2AIconHeadset from '../../assets/contact/call2a_headset_210320.svg';
import Call2AIconLocation from '../../assets/contact/call2a_location_210320.svg';


import Slider from "../components/carousel";
import Bestagons from "../components/bestagons";
import Contact from "../components/contact";
import {Link} from "gatsby";
import ProductBlock from "../components/productBlock";
import Theme from "../styles/theme";
import {SolutionBlock} from "../components/solutionBlock";
import {Carousel, CarouselItem} from "react-bootstrap";
import LandingPage from "../components/landingPage";
import CircuitBoardPhoto from "../../assets/landing/slider_sol_72dpi_201117.png";
import AIPhoto from "../../assets/landing/slider_ai_72dpi_201118.png";
import RegexPhoto from "../../assets/landing/slider_regex_72dpi_201117.png";
import MidpageLogo from "../components/midpageLogo";
import Snake from "../components/snake";
import ScrollAnchor from "../components/scrollAnchor";
import SectionContainer from "../components/sectionContainer";
import AboutSection from "../components/about";
import TripleGrid from "../components/tripleGrid";
import Referrals from "../components/referrals";

const useStyles = makeStyles({
  logoButtonIcon3: {
    // Scale height according to 16:9 aspect ratio, constraining by width as aspect ratio becomes too thin
    height: "min(5vh, 25vw)",
    filter: "drop-shadow(0 calc(min(5vh, 25vw) * 0.069) calc(min(5vh, 25vw) * 0.0865) #000000)",
    marginBottom: "5vh", // Override default
    marginTop: "5vh",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
});

export default () => {
  const classes = useStyles();

  return (
    <Layout>
      {/** Landing page/Carousel **/}
      <Slider/>

      {/** Logo-intermediate **/}
      <MidpageLogo/>

      <ScrollAnchor id="learn"/>

      {/** About **/}
      <AboutSection>
        We're a company founded by computer scientists from across the globe,
        on a mission to accelerate GPU workloads.
        <br/><br/>
        Our experience with the latest GPU architectures and development
        of best-in-class libraries help you get the most out of your compute resources
        without making sacrifices.
      </AboutSection>

      {/** Product Descriptions - binary background */}
      <SectionContainer backgroundImage={BinaryBackground} topGradient={true} bottomGradient={true}>
        <TripleGrid>
          <ProductBlock icon={ExpertiseIconBrain} title="High Performance Computing">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices felis eu posuere dignissim. In
            lacinia magna quis tincidunt congue. Nam ac mi enim. Praesent auctor, lectus ut ultrices scelerisque, enim
            purus consequat mauris, porta ullamcorper tortor magna ac turpis. Nulla eget arcu nec velit dictum rutrum.
            Mauris sit amet vehicula leo, sed aliquet ligula.
          </ProductBlock>
          <ProductBlock icon={ExpertiseIconRocket} title="High Performance Computing">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices felis eu posuere dignissim. In
            lacinia magna quis tincidunt congue. Nam ac mi enim. Praesent auctor, lectus ut ultrices scelerisque, enim
            purus consequat mauris, porta ullamcorper tortor magna ac turpis. Nulla eget arcu nec velit dictum rutrum.
            Mauris sit amet vehicula leo, sed aliquet ligula.
          </ProductBlock>
          <ProductBlock icon={ExpertiseIconTool} title="High Performance Computing">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices felis eu posuere dignissim. In
            lacinia magna quis tincidunt congue. Nam ac mi enim. Praesent auctor, lectus ut ultrices scelerisque, enim
            purus consequat mauris, porta ullamcorper tortor magna ac turpis. Nulla eget arcu nec velit dictum rutrum.
            Mauris sit amet vehicula leo, sed aliquet ligula.
          </ProductBlock>
        </TripleGrid>
      </SectionContainer>

      <ScrollAnchor id="contact"/>

      {/** What can we do for you? **/}
      <div style={{
         minHeight: "calc(70vh - 3.75rem)",
         backgroundColor: "#003b6d",
       }}
      >
        <div
          style={{
            paddingBottom: "min(7.5vh, 25vw)",
          }}
        >
          <div
            style={{
              paddingTop: "5.5vh",
              paddingBottom: "5.5vh",
              margin: "0 auto",
              width: "70vw",
              textAlign: 'center'
            }}
          >
            <Typography variant="h2" color="secondary" style={{fontSize: "min(min(4.65vw, 4.74vh), 6.4rem)"}}>
              {"< what can we do for you? >"}
            </Typography>

            <Typography variant="body1" color="secondary" style={{marginTop: "6.3vh", fontSize: "min(min(2.1vw, 1.48vh), 2rem)"}}>
              almost all of our solutions are tailored to your specifications<br/>
              we pride ourselves in building excellent partnerships<br/>
              <br/>
              let us help you find what you need<br/>
              get in touch
            </Typography>

            <Grid container justify="center" style={{marginTop: "5.5vh"}}>
              <Grid item>
                <img src={Call2AIconPhone} style={{height: "min(10vh, 38.376vw)", marginLeft: "4.8vh", marginRight: "4.8vh", marginBottom: "5.5vh", filter: "drop-shadow(0 2px 4px #000000)"}}/>
              </Grid>
              <Grid item>
                <img src={Call2AIconEmail} style={{height: "min(10vh, 38.376vw)", marginLeft: "4.8vh", marginRight: "4.8vh", marginBottom: "5.5vh", filter: "drop-shadow(0 2px 4px #000000)"}}/>
              </Grid>
              <Grid item>
                <img src={Call2AIconChat} style={{height: "min(10vh, 38.376vw)", marginLeft: "4.8vh", marginRight: "4.8vh", marginBottom: "5.5vh", filter: "drop-shadow(0 2px 4px #000000)"}}/>
              </Grid>
            </Grid>

            <Typography variant="body1" color="secondary" style={{fontSize: "min(min(2.1vw, 1.48vh), 2rem)"}}>
              or<br/>
              explore solutions
            </Typography>
          </div>
        </div>
      </div>

      {/** Explore solutions logo **/}
      <Snake/>

      <ScrollAnchor id="solutions"/>

      {/** Solutions **/}
      <SectionContainer backgroundImage={SolutionsBackground} topGradient={true}>
        <TripleGrid>
          {SolutionBlock(
            SolutionsIconRegex,
            "SpecRegex",
            "SpecRegEx is the result of novel advancements in graph\n" +
            "theory. A truly modern RegEx library; feature-complete and\n" +
            "extremely fast.\n\n" +
            "Performing spectacularly across the board, SpecRegEx\n" +
            "consistently reaches speeds that are an order of magnitude\n" +
            "faster than conventional RegEx libraries while still being\n" +
            "PCRE-equivalent",
            "complete",
            "library",
            "ready to use"
          )}
          {SolutionBlock(
            SolutionsIconAI,
            "AI Unleash",
            "A full-service upgrade for your AI. Our team of engineers\n" +
            "takes a look at your code and provides software/library level\n" +
            "improvements to your AI.\n\n" +
            "Fully transparent with a strong emphasis on performance\n" +
            "and tailor-made solutions, while offering a Faster-or-Free guarantee.",
            "+300%*",
            "faster AI",
            "on average"
          )}
          {SolutionBlock(
            SolutionsIconBespoke,
            "Bespoke",
            "We create solutions for companies that require greater\n" +
            "productivity, higher performance and better tools to get the\n" +
            "most out of your engineers and hardware.\n" +
            "We understand that every business has unique needs.\n" +
            "Therefore, we offer bespoke customized solutions for your\n" +
            "company",
            "faster",
            "solution",
            "today"
          )}
        </TripleGrid>

        {/** Referrals **/}
        <Referrals/>

        <img src={MidSiteLogo} className={classes.logoButtonIcon3} alt="Scroll down logo button"/>

        <Typography color="secondary" style={{paddingBottom: "5vh", textAlign: "center"}}>
          We're continuously developing the fastest software for you
        </Typography>
      </SectionContainer>

      {/** TBD **/}


      {/** TBD **/}

      {/** Contact **/}
      <div style={{
        backgroundColor: "#0e2847",
        minHeight: "calc(100vh - 3.75rem)",
        position: "relative", // Needed for absolute among children

        // Horizontal centering of children
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
          <Typography color="secondary" style={{fontSize: "2rem", marginLeft: "auto", marginRight: "auto", marginBottom: "5vh", textAlign: "center"}}>
            We'd love to hear from you
          </Typography>
          <Grid container justify="center">
            <Contact/>

            <Grid item style={{
              fontSize: "min(1vw, 0.56vh)",
              width: "70em",
              backgroundColor: "#0E56A8",
              margin: "1.785em"
            }}>
              <Grid container direction="column">
                <Grid item xs={12} style={{display: "flex", alignItems: "center", margin: "3.57em"}}>
                  <img src={Call2AIconMail} style={{marginBottom: 0, height: "min(10vh, 38.376vw)", marginRight: "3.57em"}}/>
                  <Typography color="secondary" style={{fontSize: "2.64em"}}>
                    83 Green Lanes, London<br/>
                    England<br/>
                    N13 4BS
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", alignItems: "center", margin: "3.57em"}}>
                  <img src={Call2AIconPhone} style={{marginBottom: 0, height: "min(10vh, 38.376vw)", marginRight: "3.57em"}}/>
                  <Typography color="secondary" style={{fontSize: "2.64em"}}>
                    {"+44 1392 58 1900"}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", alignItems: "center", margin: "3.57em"}}>
                  <img src={Call2AIconEmail} style={{marginBottom: 0, height: "min(10vh, 38.376vw)", marginRight: "3.57em"}}/>
                  <Typography color="secondary" style={{fontSize: "2.64em"}}>
                    hello@spectralcompute.co.uk
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", alignItems: "center", margin: "3.57em"}}>
                  <img src={Call2AIconHeadset} style={{marginBottom: 0, height: "min(10vh, 38.376vw)", marginRight: "3.57em"}}/>
                  <Typography color="secondary" style={{fontSize: "2.64em"}}>
                    Chat to us
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

      </div>
      {/** TBD **/}

      {/** Bestagons! **/}
      <Bestagons/>
        <br/>
        <br/>

      {/** Team-footer **/}
      {/** TBD **/}
    </Layout>
  )
}
