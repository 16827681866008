import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import {Carousel, CarouselItem} from "react-bootstrap";

const useStyles = makeStyles({
  background: {
    width: "40vw",
    height: "30vh",
    padding: "2vmin",
    backgroundColor: "#0D2847"
  }
});

const ReferralsItem = ({children, name, company} : {children: React.ReactNode, name: string, company: string}) => {
  const classes = useStyles();

  return (
    <CarouselItem>
      <div className={classes.background}>
        <Typography style={{fontSize: "1rem"}}>
          {children}
        </Typography>
        <div style={{textAlign: "right"}}>
          <Typography style={{fontSize: "2rem"}}>
            {name}
          </Typography>
          <Typography style={{fontSize: "1rem"}}>
            {company}
          </Typography>
        </div>
      </div>
    </CarouselItem>
  )
}

export const Referrals = () => {
  return (
    <Carousel
      interval={6000}
      indicators={false}
      prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" style={{height: "4vmin", width: "4vmin"}}/>}
      nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" style={{height: "4vmin", width: "4vmin"}}/>}
      style={{width: "100%"}}
    >
      <ReferralsItem name="name1" company="company1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
        eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.
      </ReferralsItem>
      <ReferralsItem name="name2" company="company2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
        eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.
      </ReferralsItem>
      <ReferralsItem name="name3" company="company3">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
        eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.
      </ReferralsItem>
    </Carousel>
  )
}

export default Referrals;
