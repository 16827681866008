import {Button, ButtonGroup, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import React from "react";

import SolutionsIconMessage from '../../assets/solutions/solution_call2a_message_noshade_210323.svg';
import SolutionsIconInfo from '../../assets/solutions/solution_call2a_info_noshade_210320.svg';

const useStyles = makeStyles({
  expertiseCell: {

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90%",
    //aspectRatio: "1 / 1.618",

    //borderRadius: '0.5rem',


    padding: "min(1.8vh, 2.43vw)",
    // Top graphic
    '& img': {
      height: '25%',
      margin: 0,
      marginLeft: 'auto'
    }
  },
  expertiseHeadline: {
    fontSize: "min(min(5.11vh, 8.93vw), 4rem)",
    marginBottom: "min(1vh, 1.25vw)",
  },
  expertiseText: {
    fontSize: "min(min(1.63vh, 2.84vw), 2rem)",
    lineHeight: 1.21
  }
});

export const SolutionBlock = (
  icon: string,
  title: string,
  textContent: string,
  shortPhrase1: string,
  shortPhrase2: string,
  shortPhrase3: string,
) => {
  const classes = useStyles();

  return (
    <Grid item style={{
      margin: "min(1.8vh, 2.43vw)",
      backgroundColor: "#0D2847",
      height: "calc(min(51.5vh, 70vw) * 1.252)",
      width: "min(51.5vh, 70vw)",
      maxWidth: "min(51.5vh, 70vw)",
      position: "relative",
      zIndex: 1, // Bring above gradient
    }}>
      <div className={classes.expertiseCell}>
        <img src={icon} alt="product block icon"/>
        <Typography
          variant="h2"
          color="secondary"
          className={classes.expertiseHeadline}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="secondary"
          className={classes.expertiseText}
        >
          {textContent}
        </Typography>
        <div style={{padding: "min(1.8vh, 2.43vw)", backgroundColor: "rgba(224, 223, 222, .20)", textAlign: "right"}}>
            <Typography color="secondary" style={{fontSize: "min(min(1.48vh, 2.42vw), 2rem)"}}>{shortPhrase1}</Typography>
            <Typography color="secondary" style={{fontSize: "min(min(1.48vh, 2.42vw), 2rem)"}}>{shortPhrase2}</Typography>
            <Typography color="secondary" style={{fontSize: "min(min(1.48vh, 2.42vw), 2rem)"}}>{shortPhrase3}</Typography>
        </div>
      </div>
      <ButtonGroup fullWidth={true} style={{height: "10%"}}>
        <Button style={{borderRadius: 0, backgroundColor: "#1A3C6B", width: "50%", padding: "min(1.8vh, 2.43vw)",}}>
          <img src={SolutionsIconMessage} style={{margin: 0, marginRight: "min(1.8vh, 2.43vw)", height: "calc(min(51.5vh, 70vw) * 0.1252 * 0.7)"}}/>
          <Typography color="secondary" style={{fontSize: "min(min(1.48vh, 2.35vw), 2rem)"}}>
            implement today
          </Typography>
        </Button>
        <Button style={{borderRadius: 0, width: "50%", padding: "min(1.8vh, 2.43vw)",}}>
          <img src={SolutionsIconInfo} style={{margin: 0, marginRight: "min(1.8vh, 2.43vw)", height: "calc(min(51.5vh, 70vw) * 0.1252 * 0.7)"}}/>
          <Typography color="secondary" style={{fontSize: "min(min(1.48vh, 2.35vw), 2rem)"}}>
            more info
          </Typography>
        </Button>
      </ButtonGroup>
    </Grid>
  );
}

export default SolutionBlock;
