import React from "react";
import {Grid} from "@material-ui/core";

export const TripleGrid = ({children} : {children: React.ReactNode}) => {
  return (
    <Grid container
      alignItems="center"
      justify="center"
      style={{
        padding: "15vh 3.5vw",
        position: "relative", // Enable zIndex
        zIndex: 1
      }}
    >
      {children}
    </Grid>
  )
}

export default TripleGrid;
