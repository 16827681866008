import React from "react";

interface Point {
    x: number;
    y: number;
}

let pointy_corner = (center: Point, size: number, i: number) => {
    let angle_deg = 60 * i - 30;
    let angle_rad = Math.PI / 180 * angle_deg;

    return {
        x: center.x + size * Math.cos(angle_rad),
        y: center.y + size * Math.sin(angle_rad)
    }
}

let bestagon_points = (center: Point, size: number) => 
    [0,1,2,3,4,5].map(
        (i) => {
            let {x,y} = pointy_corner(center, size, i);
            return `${x},${y}`
        }
    ).join(' ');

export default () => {
    return <svg height="100vh" width="100%">
        <polygon
            points={bestagon_points({x: 150, y: 150}, 100)}
            style={{
                fill: "lime",
                stroke: "purple",
                strokeWidth: "1"
            }} />
    </svg>
}