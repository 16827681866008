import React, {useState} from 'react';
import {Card, TextField, Button, CircularProgress, makeStyles, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  foo: {
    //backgroundColor: "#FFFFFF",
    //width: "100%",
  }
});

export const Contact = () => {
  // Set up the state
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [country, setCountry] = useState("");
  const [text, setText] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  const classes = useStyles();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    fetch("/contact", {
      method: "POST",
      body: JSON.stringify({
        loading: loading,
        submitMessage: submitMessage,
        name: name,
        email: email,
        organisation: organisation,
        country: country,
        text: text
      })
    }).then(() => {
      setSubmitMessage("Thanks for reaching out. You'll hear back from us");
    }).catch(() => {
      setSubmitMessage("Something went wrong, please make sure you're online and try again later");
    }).then(() => {
      setLoading(false);
    });
  };

  return (
    <Grid item style={{fontSize: "min(1vw, 0.81vh)", width: "70em", margin: "1.235em"}}>
      {loading ? <CircularProgress/> : null}
      {submitMessage ?
        <Typography>{submitMessage}</Typography> :
        <form onSubmit={onSubmit} id="contact" style={{marginBottom: 0}}>
          <TextField placeholder="Name" required margin="normal" onChange={e => setName(e.currentTarget.value)} InputProps={{className: classes.foo}} style={{width: "100%", backgroundColor: "#dfdfdf", marginTop: 0}}/>
          <br/>
          <TextField placeholder="Email" required margin="normal" onChange={e => setEmail(e.currentTarget.value)} InputProps={{className: classes.foo}} style={{width: "100%", backgroundColor: "#dfdfdf"}}/>
          <br/>
          <TextField placeholder="Organisation" required margin="normal" onChange={e => setOrganisation(e.currentTarget.value)} InputProps={{className: classes.foo}} style={{width: "100%", backgroundColor: "#dfdfdf"}}/>
          <br/>
          <TextField placeholder="Country" required margin="normal" onChange={e => setCountry(e.currentTarget.value)} InputProps={{className: classes.foo}} style={{width: "100%", backgroundColor: "#dfdfdf"}}/>
          <br/>
          <TextField
            rows={5}
            placeholder="What can we do for you?"
            multiline
            margin="normal"
            onChange={e => setText(e.currentTarget.value)}
            style={{width: "100%", backgroundColor: "#dfdfdf"}}
          />
          <br/>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{
              width: "100%",
              marginTop: ".5rem",
              backgroundColor: "#0E56A8"
            }}
          >
            Submit
          </Button>
        </form>
      }
    </Grid>
  )
}

export default Contact;
